<template>
	<photo img="/img/e9/p2/celex" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Prefecture',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: null,
						name: 'D. Formación Integral e Institucional',
                        desc: `El departamento de formación integral e institucional ofrece:`,
						left: 40, //'225px',
						top: 30, //'280px',
						width: 10.5, //'180px',
						height: 18, //'230px'
                        p: 100,
                        r: -1
					},
                    {
						link: null,
						name: 'D. Inovación Educativa',
                        desc: ``,
						left: 40, //'225px',
						top: 50, //'280px',
						width: 11, //'180px',
						height: 18, //'230px'
                        p: 100,
                        r: -1
					},
                    {
						link: 'E9.P2.Direction',
						name: 'Dirección',
						left: 10.5, //'225px',
						top: 35.1, //'280px',
						width: 4.5, //'180px',
						height: 26.5, //'230px'
                        p: 100,
                        r: 15
					},
                    {
						link: 'E9.P3.Direction',
						name: 'Sociales',
						left: 60, //'1700px',
						top: 26, //'220px',
						width: 19.5, //'180px',
						height: 41 //'160px'
					},
                    {
						link: 'E9.P1.Prefecture',
						name: 'Salones',
						left: 70, //'1900px',
						top: 47.5, //'380px',
						width: 9.5, //'180px',
						height: 23.5 //'180px'
					}, 
				]
			}
		}
	}
</script>
