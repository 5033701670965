<template>
	<photo img="/img/e9/p2/direction" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Prefecture',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.P2.Celex',
						name: 'CELEX',
						left: 43.5, //'980px',
						top: 34, //'310px',
						width: 7, //'150px',
						height: 31, //'180px'
                        p: 200,
                        r: 6
					},
                    {
						link: 'E9.P3.Cubicle',
						name: 'Dep. Mat',
						left: 66.9, //'1435px',
						top: 40, //'310px',
						width: 10, //'125px',
						height: 17 //'140px'
					},
                    {
						link: 'E9.P1.21',
						name: 'Salones',
						left: 72.5, //'1560px',
						top: 49, //'310px',
						width: 4.8, //'125px',
						height: 10 //'140px'
					},
				]
			}
		}
	}
</script>
